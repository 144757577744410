import { createStore } from 'vuex';
import themeLayout from './modules/themeLayout/actionCreator';
import auth from './modules/authentication/axios/actionCreator';
import role from './modules/system/role/actionCreator';
import menu from './modules/system/menu/actionCreator';
import user from './modules/system/user/actionCreator';
import roleGroup from './modules/system/roleGroup/actionCreator';
import location from './modules/system/location/actionCreator';
import nationality from './modules/system/nationality/actionCreator';
import person from './modules/identityObject/person/actionCreator';
import vehicle from './modules/identityObject/vehicle/actionCreator';
import ioGroup from './modules/ioGroup/actionCreator';
import userGroup from './modules/system/user-group/actionCreator';
import ioManagement from './modules/ioManagement/actionCreator';
import deviceManagement from './modules/deviceManagement/actionCreator';
import channelManagement from './modules/channelManagement/actionCreator';
import objManagement from './modules/objManagement/actionCreator';
import listSelect from './modules/listSelect/actionCreator';
import coordinator from './modules/strategicCoordination/actionCreator' 
import identificationEvent from './modules/identificationEvent/actionCreator';
import tracingObject from './modules/tracingObject/actionCreator';
import alertConfiguration from './modules/alert-configuration/actionCreator';
import reportStatistical from './modules/reportStatistical/actionCreator';
import cameras from './modules/camera/actionCreator';
import inOutEvent from './modules/in-out-event/actionCreator';
import eventVerify from './modules/eventVerify/actionCreator';
import weaponEvent from './modules/weaponEvent/actionCreator';

export default createStore({
  modules: {
    themeLayout,
    auth,
    role,
    menu,
    user,
    person,
    vehicle,
    location,
    nationality,
    ioGroup,
    userGroup,
    ioManagement,
    roleGroup,
    deviceManagement,
    channelManagement,
    objManagement,
    listSelect,
    coordinator,
    identificationEvent,
    tracingObject,
    alertConfiguration,
    reportStatistical,
    cameras,
    inOutEvent,
    eventVerify,
    weaponEvent
  },
});
