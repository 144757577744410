import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';
//import { notification } from 'ant-design-vue';
//import { i18n } from '@/main';

const state = () => ({
    loading: false,
    draftFrequencies: [],
    draftNonCheckouts: [],
    frequencies: [],
    nonCheckouts: [],
    numberOfOccurrences: {
        total: 0,
        chart: {
            columns: [],
            human: [],
            vehicle: [],
        }
    },
    numberOfIntrusions: {
        total: 0,
        chart: {
            columns: [],
            intrusion: [],
            normal: [],
        }
    }
});

const actions = {
    async getFrequencies({state}, params) {
        try {
            state.loading = true
            const response = await DataService.callApi(
                ConstantAPI.REPORT_STATISTICAL.GET_FREQUENCIES,
                null,
                params
            );
            checkResponse(response, () => {
                state.draftFrequencies = response.data ? response.data : []
                state.frequencies = state.draftFrequencies.slice(0, 10);
            })
        } catch (e) {
            console.log(e)
        } finally {
            state.loading = false;
        }
    },
    async getNumberOfOccurrences({state}, params) {
        try {
            state.loading = true;
            const response = await DataService.callApi(
                ConstantAPI.REPORT_STATISTICAL.GET_NUMBER_OF_OCCURRENCES,
                null,
                params
            );
            checkResponse(response, () => {
                state.numberOfOccurrences = response.data ? response.data : {}
            })
        } catch (e) {
            console.log(e)
        } finally {
            state.loading = false;
        }
    },
    async getNonCheckout({state}, params) {
        const convertParams = {
            groupCameraId: params.group_id,
            chartBy: params.type,
            size: 50,
            dateFrom: params.time_from,
            dateTo: params.time_to

        }
        try {
            state.loading = true;
            const response = await DataService.callApi(
                ConstantAPI.EVENT.INTRUSION,
                null,
                convertParams
            );
            checkResponse(response, () => {
                state.draftNonCheckouts = response.data ? response.data : []
                state.nonCheckouts = state.draftNonCheckouts.slice(0, 10);
            })
        } catch (e) {
            console.log(e)
        } finally {
            state.loading = false;
        }
    },
    async getNumberOfIntrusions({state}, params) {
        try {
            state.loading = true;
            const response = await DataService.callApi(
                ConstantAPI.REPORT_STATISTICAL.GET_NUMBER_OF_INTRUSIONS,
                null,
                params
            );
            checkResponse(response, () => {
                state.numberOfIntrusions = response.data ? response.data : {}
            })
        } catch (e) {
            console.log(e);
        } finally {
            state.loading = false;
        }
    },
    
    fetchFrequencies({state}, number) {
        state.frequencies = state.draftFrequencies.slice(0, number);
    },

    fetchNonCheckouts({state}, number) {
        state.nonCheckouts = state.draftNonCheckouts.slice(0, number);
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};