const AUTHORIZATION_SEVICE = '/authorization-service';
export const USER_SEVICE = '/user-service';
export const EMS_SEVICE = '/ems-service';
export const CHAT_SERVICE = '/chat-service';
export const VMS_SERVICE = '/vms-service';
export default {
  role: {
    GET_ALL: {
      url: USER_SEVICE + '/role/get-all',
      method: 'GET',
    },
    GET_BY_ID: {
      url: USER_SEVICE + '/role',
      method: 'GET',
    },
    SEARCH: {
      url: USER_SEVICE + '/role',
      method: 'GET',
    },
    CREATE: {
      url: USER_SEVICE + '/role',
      method: 'POST',
    },
    UPDATE: {
      url: USER_SEVICE + '/role',
      method: 'PUT',
    },
    DELETE: {
      url: USER_SEVICE + '/role',
      method: 'DELETE',
    },
    GET_TREE_CAMERA: {
      url: EMS_SEVICE + '/group-camera/tree',
      method: 'GET',
    },
    GET_USER_IN_ROLE: {
      url: USER_SEVICE + '/user/get-user-in-role',
      method: 'GET',
    },
    GET_TREE: {
      url: EMS_SEVICE + '/groups',
      method: 'GET',
    },
  },
  role_menu: {
    GET_BY_ROLE: {
      url: USER_SEVICE + '/role-menu',
      method: 'GET',
    },
  },
  user_role: {
    GET_BY_USER: {
      url: USER_SEVICE + '/user-role',
      method: 'GET',
    },
  },
  menu: {
    SEARCH: {
      url: USER_SEVICE + '/menu',
      method: 'GET',
    },
    CREATE: {
      url: USER_SEVICE + '/menu',
      method: 'POST',
    },
    UPDATE: {
      url: USER_SEVICE + '/menu',
      method: 'PUT',
    },
    DELETE: {
      url: USER_SEVICE + '/menu',
      method: 'DELETE',
    },
  },
  user: {
    GET_ALL: {
      url: USER_SEVICE + '/user/get-all',
      method: 'GET',
    },
    SEARCH: {
      url: USER_SEVICE + '/user',
      method: 'GET',
    },
    CREATE: {
      url: USER_SEVICE + '/user',
      method: 'POST',
    },
    UPDATE: {
      url: USER_SEVICE + '/user',
      method: 'PUT',
    },
    PATCH_UPDATE: {
      url: USER_SEVICE + '/user',
      method: 'PATCH',
    },
    DELETE: {
      url: USER_SEVICE + '/user',
      method: 'DELETE',
    },
    CHECK_EXISTED: {
      url: USER_SEVICE + '/user/check',
      method: 'GET',
    },
    GET_USER_INFO: {
      url: USER_SEVICE + '/user/get-user-info',
      method: 'GET',
    },
    GET_SUGGEST: {
      url: USER_SEVICE + '/user/suggest',
      method: 'GET',
    },
    GET_NOTIFICATIONS: {
      url: USER_SEVICE + '/notify',
      method: 'GET',
    },
    MARK_READ_NOTIFICATION: {
      url: USER_SEVICE + '/notify/seen',
      method: 'PATCH',
    },
    GET_LIST_USER_BY_ROLE: {
      url: USER_SEVICE + '/user/get-user-in-role',
      method: 'GET',
    },
    RESET_DEFAULT_PASSWORD_BY_USER: {
      url: USER_SEVICE + '/user',
      method: 'PUT',
    },
  },
  auth: {
    AUTHENTICATE: {
      url: AUTHORIZATION_SEVICE + '/oauth/authenticate',
      method: 'POST',
    },
    GET_TOKEN: {
      url: AUTHORIZATION_SEVICE + '/oauth/token',
      method: 'POST',
    },
    REFRESH_TOKEN: {
      url: AUTHORIZATION_SEVICE + '/oauth/refresh-token',
      method: 'POST',
    },
    LOGOUT: {
      url: AUTHORIZATION_SEVICE + '/oauth/revoke',
      method: 'DELETE',
    },
    CHANGE_PASSWORD: {
      url: USER_SEVICE + '/user',
      method: 'PATCH',
    },
    FORGOT: {
      url: USER_SEVICE + '/user/forgot',
      method: 'POST',
    },
    RESET_PASSWORD: {
      url: USER_SEVICE + '/user/reset-password',
      method: 'PATCH',
    },
    PATCH_UPDATE: {
      url: USER_SEVICE + '/user',
      method: 'PATCH',
    },
  },
  upload_service: {
    UPLOAD: {
      url: '/upload',
      method: 'POST',
    },
  },
  indentity_management: {
    SEARCH: {
      url: EMS_SEVICE + '/persons',
      method: 'GET',
    },
    GET_PERSON: {
      url: EMS_SEVICE + '/persons',
      method: 'GET',
    },
    GET_VEHICLE: {
      url: EMS_SEVICE + '/vehicles',
      method: 'GET',
    },
    UPDATE_PERSON: {
      url: EMS_SEVICE + '/persons',
      method: 'PUT',
    },
    UPDATE_VEHICLE: {
      url: EMS_SEVICE + '/vehicles',
      method: 'PUT',
    },
    CREATE_PERSON: {
      url: EMS_SEVICE + '/persons',
      method: 'POST',
    },
    CREATE_VEHICLE: {
      url: EMS_SEVICE + '/vehicles',
      method: 'POST',
    },
    GET_APP_PARAMS: {
      url: EMS_SEVICE + '/app-params',
      method: 'GET',
    },
    GET_ALL_LOCATION: {
      url: EMS_SEVICE + '/provinces/get-all',
      method: 'GET',
    },
    GET_PERSON_SUGGEST: {
      url: EMS_SEVICE + '/persons/suggest',
      method: 'GET',
    },
    DELETE_PERSON: {
      url: EMS_SEVICE + '/persons',
      method: 'DELETE',
    },
    DELETE_VEHICLE: {
      url: EMS_SEVICE + '/vehicles',
      method: 'DELETE',
    },
    GET_IO_SELECT: {
      url: EMS_SEVICE + '/persons/io-selects',
      method: 'GET',
    },
  },
  io_group: {
    GET_TREE: {
      url: EMS_SEVICE + '/groups',
      method: 'GET',
    },
    GET_ALL: {
      url: EMS_SEVICE + '/groups/get-all',
      method: 'GET',
    },
    CREATE: {
      url: EMS_SEVICE + '/groups',
      method: 'POST',
    },
    UPDATE: {
      url: EMS_SEVICE + '/groups',
      method: 'PUT',
    },
    GET_ALL_IO: {
      url: EMS_SEVICE + '/io-group',
      method: 'GET',
    },
    GET_IO_BY_ID: {
      url: EMS_SEVICE + '/groups/',
      method: 'GET',
    },
    DELETE_GROUP_IO: {
      url: EMS_SEVICE + '/groups/',
      method: 'DELETE',
    },
    ADD_IO_TO_GROUP: {
      url: EMS_SEVICE + '/io-group',
      method: 'POST',
    },
    DELETE_IO_IN_GROUP: {
      url: EMS_SEVICE + '/io-group',
      method: 'DELETE',
    },
    GET_GROUP_BY_ID: {
      url: EMS_SEVICE + '/groups/',
      method: 'GET',
    },
    GET_LIST_ALL_IO: {
      url: EMS_SEVICE + '/groups/get-io',
      method: 'GET',
    },
    GET_ALL_GROUP: {
      url: EMS_SEVICE + '/groups/all-group',
      method: 'GET',
    },
    GET_GROUP_BY_USER: {
      url: EMS_SEVICE + '/groups/get-all-by-user',
      method: 'GET',
    },
    GET_TREE_BY_ID: {
      url: EMS_SEVICE + '/groups/tree',
      method: 'GET',
    },
    GROUP_SEARCH_ADVANCED: {
      url: EMS_SEVICE + '/groups/advance',
      method: 'GET',
    },
    GET_LIST_IO_NOT_IN_GROUP: {
      url: EMS_SEVICE + '/groups/io-not-in-group',
      method: 'GET',
    },
    GET_LIST_DATA_SOCIAL: {
      url: EMS_SEVICE + '/io-persons/social',
      method: 'GET',
    },
  },
  user_group: {
    SEARCH: {
      url: EMS_SEVICE + '/groups',
      method: 'GET',
    },
    CREATE: {
      url: EMS_SEVICE + '/groups',
      method: 'POST',
    },
    UPDATE: {
      url: EMS_SEVICE + '/groups',
      method: 'PUT',
    },
    DELETE: {
      url: EMS_SEVICE + '/groups',
      method: 'DELETE',
    },
    GET_BY_USER: {
      url: EMS_SEVICE + '/user-groups',
      method: 'GET',
    },
    CREATE_USE_GROUP: {
      url: EMS_SEVICE + '/user-groups',
      method: 'POST',
    },
    PERMISSION: {
      url: EMS_SEVICE + '/user-groups/permission',
      method: 'POST',
    },
  },
  warning: {
    GET: {
      url: EMS_SEVICE + '/warning-level',
      method: 'GET',
    },
  },
  OBJECT_INFORMATION: {
    human: {
      CREATE: {
        url: EMS_SEVICE + '/io-persons',
        method: 'POST',
      },
      DETAIL: {
        url: EMS_SEVICE + '/io-persons/',
        method: 'GET',
      },
      UPDATE: {
        url: EMS_SEVICE + '/io-persons',
        method: 'PUT',
      },
    },
    vehicle: {
      CREATE: {
        url: EMS_SEVICE + '/vehicles',
        method: 'POST',
      },
      DETAIL: {
        url: EMS_SEVICE + '/vehicles/',
        method: 'GET',
      },
      UPDATE: {
        url: EMS_SEVICE + '/vehicles',
        method: 'PUT',
      },
    },
    symbol: {
      CREATE_SYMBOL: {
        url: EMS_SEVICE + '/io-icon',
        method: 'POST',
      },
      DETAIL_SYMBOL: {
        url: EMS_SEVICE + '/io-icon/',
        method: 'GET',
      },
      UPDATE_SYMBOL: {
        url: EMS_SEVICE + '/io-icon',
        method: 'PUT',
      },
    },
    address: {
      CREATE: {
        url: EMS_SEVICE + '/io-address',
        method: 'POST',
      },
      DETAIL: {
        url: EMS_SEVICE + '/io-address/',
        method: 'GET',
      },
      UPDATE: {
        url: EMS_SEVICE + '/io-address',
        method: 'PUT',
      },
    },
  },
  NATIONALITY: {
    GET_ALL: {
      url: EMS_SEVICE + '/nationality',
      method: 'GET',
    },
  },
  APP_PARAMS: {
    url: EMS_SEVICE + '/app-params',
    method: 'GET',
  },
  OBJECT_MANAGEMENT: {
    GET_BY_CONDITION: {
      url: EMS_SEVICE + '/identity-object',
      method: 'GET',
    },
    DELETE: {
      url: EMS_SEVICE + '/identity-object/',
      method: 'DELETE',
    },
    GET_LIST_SIMILAR: {
      url: EMS_SEVICE + '/identity-object/similar',
      method: 'GET',
    },
    DETAIL_OBJ_RELATE: {
      url: EMS_SEVICE + '/identity-object/relate/',
      method: 'GET',
    },
    MERGE_PROFILE: {
      url: EMS_SEVICE + '/identity-object/merge-object',
      method: 'POST',
    },
  },
  IDENTIFICATION_EVENT: {
    GET_LIST: {
      url: EMS_SEVICE + '/event',
      method: 'GET',
    },
    GET_LIST_ADVANCED: {
      url: EMS_SEVICE + '/event/advantage',
      method: 'GET',
    },
    GET_BY_ID: {
      url: EMS_SEVICE + '/event/',
      method: 'GET',
    },
    DELETE: {
      url: EMS_SEVICE + '/event/',
      method: 'DELETE',
    },
    NAVIGATION: {
      url: EMS_SEVICE + '/event/navigation',
      method: 'GET',
    },
    INTRUSION_CHART: {
      url: EMS_SEVICE + '/event/intrusion-chart',
      method: 'GET',
    },
    GET_BY_IMAGE: {
      url: EMS_SEVICE + '/identity-object/human-by-img',
      method: 'GET',
    },
    VIDEO_EVENT:{
      url: VMS_SERVICE + '/api/recording',
      method: 'GET',
    }
  },
  TRACING_OBJECT: {
    GET_BY_ID: {
      url: EMS_SEVICE + '/tracing-object/',
      method: 'GET',
    },
    GET_LOCATION: {
      url: EMS_SEVICE + '/tracing-object/location/',
      method: 'GET',
    },
    CHANGE_STATUS: {
      url: EMS_SEVICE + '/tracing-object/change-status',
      method: 'PUT',
    },
    TRACING: {
      url: EMS_SEVICE + '/identity-object/tracing',
      method: 'PUT',
    },
    GET_LIST_GROUP_CAMERA: {
      url: EMS_SEVICE + '/group-camera',
      method: 'GET',
    },
    GET_FREQUENCY: {
      url: EMS_SEVICE + '/tracing-object/frequency/',
      method: 'GET',
    },
  },
  CAMERA: {
    GET_ALL: {
      url: EMS_SEVICE + '/cameras',
      method: 'GET',
    },
    GET_BY_GROUPS: {
      url: EMS_SEVICE + '/cameras/get-by-group-camera',
      method: 'GET',
    },
    GET_LIST_CAMERA_INFO: {
      url: EMS_SEVICE + '/cameras/get-list-camera-info',
      method: 'GET',
    },
    GET_LIST_GROUP_CAMERA: {
      url: EMS_SEVICE + '/group-camera',
      method: 'GET',
    },
  },
  ALERT: {
    GET_BY_CONDITION: {
      url: EMS_SEVICE + '/warning-service',
      method: 'GET',
    },
    CREATE: {
      url: EMS_SEVICE + '/warning-service',
      method: 'POST',
    },
    UPDATE: {
      url: EMS_SEVICE + '/warning-service',
      method: 'PUT',
    },
    GET_BY_ID: {
      url: EMS_SEVICE + '/warning-service/',
      method: 'GET',
    },
    DELETE: {
      url: EMS_SEVICE + '/warning-service/',
      method: 'DELETE',
    },
    GET_LIST_ALL_IO: {
      url: EMS_SEVICE + '/identity-object',
      method: 'GET',
    },
    GET_LIST_BY_IDS: {
      url: EMS_SEVICE + '/identity-object/get-by-ids',
      method: 'GET',
    },
  },
  IO_SUGGEST: {
    PERSON: {
      url: EMS_SEVICE + '/io-persons/suggest',
      method: 'GET',
    },
    VEHICLE: {
      url: EMS_SEVICE + '/vehicles/suggest',
      method: 'GET',
    },
  },
  DEVICE: {
    GET_ALL: {
      url: EMS_SEVICE + '/devices',
      method: 'GET',
    },
  },
  REPORT_STATISTICAL: {
    GET_FREQUENCIES: {
      url: EMS_SEVICE + '/dashboard/frequency',
      method: 'GET',
    },
    GET_NUMBER_OF_OCCURRENCES: {
      url: EMS_SEVICE + '/dashboard/number-of-occurrences',
      method: 'GET',
    },
    GET_NON_CHECKOUT: {
      url: EMS_SEVICE + '/dashboard/non-checkout',
      method: 'GET',
    },
    GET_NUMBER_OF_INTRUSIONS: {
      url: EMS_SEVICE + '/dashboard/number-of-intrusions',
      method: 'GET',
    },
  },
  EVENT: {
    GET_ALL: {
      url: EMS_SEVICE + '/event',
      method: 'GET',
    },
    GET_BY_CONDITION: {
      url: EMS_SEVICE + '/event/histories',
      method: 'GET',
    },
    DELETE: {
      url: EMS_SEVICE + '/event/',
      method: 'DELETE',
    },
    DETAIL: {
      url: EMS_SEVICE + '/event/',
      method: 'GET',
    },
    INTRUSION: {
      url: EMS_SEVICE + '/event/intrusion',
      method: 'GET',
    },
    INTRUSION_NAVIGATION: {
      url: EMS_SEVICE + '/event/intrusion/navigation',
      method: 'GET',
    },
    IN_OUT_NAVIGATION: {
      url: EMS_SEVICE + '/event/in-out/navigation',
      method: 'GET',
    },
  },
  IN_OUT_EVENT: {
    GET_CHART_BY_CONDITION: {
      url: EMS_SEVICE + '/event/statistic-in-out',
      method: 'GET',
    },
    LIST: {
      url: EMS_SEVICE + '/event/in-out',
      method: 'GET',
    },
  },
  EVENT_VERIFY: {
    GET_LIST_PROFILE: {
      url: EMS_SEVICE + '/identity-object/unverified',
      method: 'GET',
    },
    GET_LIST_EVENT: {
      url: EMS_SEVICE + '/event/unverified',
      method: 'GET',
    },
    MERGE_EVENT: {
      url: EMS_SEVICE + '/identity-object/merge-event',
      method: 'POST',
    },
  },
  LOG_MANAGEMENT: {
    GET_LIST: {
      url: EMS_SEVICE + '/log',
      method: 'GET',
    },
    EXPORT: {
      url: EMS_SEVICE + '/log/export',
      method: 'GET',
    },
  },
  channel: {
    GET_CHANNEL: {
      url: CHAT_SERVICE + '/channels/list-channels',
      method: 'GET',
    },
    SEARCH: {
      url: CHAT_SERVICE + '/channels/list-channels',
      method: 'GET',
    },
    CREATE_CHANNEL: {
      url: CHAT_SERVICE + '/channels/create-channel',
      method: 'POST',
    },
    GET_CHANNEL_BY_ID: {
      url: CHAT_SERVICE + '/channels/channel-info',
      method: 'GET',
    },
    GET_PARTICIPANTS: {
      url: CHAT_SERVICE + '/channels/channel-participants',
      method: 'GET',
    },
    UPDATE_CHANNEL: {
      url: CHAT_SERVICE + '/channels/update-info',
      method: 'POST',
    },
    ADD_USER_CHANNEL: {
      url: CHAT_SERVICE + '/channels/add-user',
      method: 'POST',
    },
    DELETE_USER_CHANNEL: {
      url: CHAT_SERVICE + '/channels/delete-user',
      method: 'DELETE',
    },
    DELETE_CHANNEL: {
      url: CHAT_SERVICE + '/channels/delete-channels',
      method: 'DELETE',
    },
  },
  messages: {
    GET_LIST_DIALOGS: {
      url: CHAT_SERVICE + '/messages/list-dialogs',
      method: 'GET',
    },
    GET_HISTORY_MESSAGES: {
      url: CHAT_SERVICE + '/messages/history',
      method: 'GET',
    },
    SEND_MESSAGES: {
      url: CHAT_SERVICE + '/messages/send-message',
      method: 'POST',
    },
    GET_LIST_CONTACT: {
      url: CHAT_SERVICE + '/contacts/list-contact',
      method: 'GET',
    },
    DELETE_MESSAGES: {
      url: CHAT_SERVICE + '/messages/delete-message',
      method: 'DELETE',
    },
    DELETE_HISTORY: {
      url: CHAT_SERVICE + '/messages/delete-history',
      method: 'DELETE',
    },
    READ_MESSAGES: {
      url: CHAT_SERVICE + '/messages/read-message',
      method: 'POST',
    },
    GET_DIALOGID:{
      url: CHAT_SERVICE + '/messages/get-dialogid',
      method: 'GET',
    },
    LIKE_CONTACT:{
      url: CHAT_SERVICE + '/contacts/like-contact',
      method: 'POST',
    },
    UN_LIKE_CONTACT:{
      url: CHAT_SERVICE + '/contacts/unlike-contact',
      method: 'POST',
    },
    GET_INFO_CALL:{
      url: CHAT_SERVICE + '/call/call-info',
      method: 'GET',
    },
    UPDATE_STATUS_CALL:{
      url: CHAT_SERVICE + '/call/status-on-call',
      method: 'PUT',
    },
    JOIN_CALL:{
      url: CHAT_SERVICE + '/call/join-call',
      method: 'POST',
    },
    LEAVE_ROOM:{
      url: CHAT_SERVICE + '/call/leave-call',
      method: 'POST',
    },
    LIKE_CHANNEL:{
      url: CHAT_SERVICE + '/channels/like-channel',
      method: 'POST',
    },
    UN_LIKE_CHANNEL:{
      url: CHAT_SERVICE + '/channels/unlike-channel',
      method: 'POST',
    },
    SHARE_MESSAGES:{
      url: CHAT_SERVICE + '/messages/send-multi-receivers',
      method: 'POST', 
    }
  },
  STATUS: {
    GET_LIST_STATUS: {
      url: CHAT_SERVICE + '/status/get-list-status',
      method: 'GET',
    },
    GET_STATUS: {
      url: CHAT_SERVICE + '/status/get-status',
      method: 'GET',
    },
    UPDATE_LOCATION: {
      url: CHAT_SERVICE + '/status/update-location',
      method: 'POST',
    },
  },
  WEAPON:{
    GET_EVENT: {
      url: EMS_SEVICE + '/event/weapon',
      method: 'GET',
    },
  }
};
