export const FORM_MODE = {
  CREATE: 1,
  UPDATE: 2,
  VIEW: 3,
};

export const GENDERS = [
  {
    value: 0,
    label: 'user.male',
  },
  {
    value: 1,
    label: 'user.female',
  },
  {
    value: 2,
    label: 'user.other',
  },
];

export const STATUS = [
  {
    value: 1,
    label: 'common.status_active',
  },
  {
    value: 0,
    label: 'common.status_inactive',
  },
];

export const STATUS_TRANSACTION = [
  {
    value: 0,
    label: 'Pending',
  },
  {
    value: 1,
    label: 'Success',
  },
  {
    value: 2,
    label: 'Process',
  },
  {
    value: 3,
    label: 'Failed',
  },
];

export const STATUS_APPROVAL = [
  {
    value: 1,
    label: 'Duyệt',
  },
  {
    value: 0,
    label: 'Chưa duyệt',
  },
];

export const defaultButton = (menu) => {
  const buttonsForm = [
    { type: 'RESET' },
    // { type: 'SEARCH' },
    { type: 'CREATE', permission: `${menu}:create` },
    { type: 'WARNING', permission: `${menu}:warning` },
    { type: 'DELETE', permission: `${menu}:delete` },
  ];
  const buttonActions = [
    { type: 'EDIT', permission: `${menu}:edit` },
    { type: 'DELETE', permission: `${menu}:delete` },
    { type: 'CHANGE_STATUS', permission: `${menu}:update-status`},
    { type: 'WARNING' , permission: `${menu}:warning`},
    { type: 'VIEW' },
  ];
  return { buttonsForm, buttonActions };
};

export const TAB_KEY = {
  INFO: 'info',
  VIOLATE: 'violate',
  GROUP: 'group',
  RELATE: 'relate',
  SOCIAL: 'social',
  CAMERA: 'camera',
  LOCATION: 'location',
  IDENTIFICATION_HISTORY: 'history',
  MAP: 'map',
};

export const OBJECT_TYPE = {
  HUMAN: 1,
  VEHICLE: 2,
  LOCATION: 3,
  SYMBOL: 4,
};

export const TYPE_TEXT = {
  HUMAN: 'HUMAN',
  VEHICLE: 'VEHICLE',
  LOCATION: 'LOCATION',
  SYMBOL: 'SYMBOL',
};

export const SEX_VALUE = [
  {
    value: 1,
    label: 'user.male',
  },
  {
    value: 2,
    label: 'user.female',
  },
  {
    value: 3,
    label: 'user.other',
  },
];

export const STATUS_VALUE = {
  INACTIVE: 0,
  ACTIVE: 1,
};

export const OBJECT_TYPE_TEXT = [
  { label: 'object_information.human', value: 1 },
  { label: 'object_information.vehicle', value: 2 },
  // { label: 'object_information.location', value: 3 },
  // { label: 'object_information.symbol', value: 4 },
];
export const OBJECT_TYPE_TEXT_VALUE = [
  { label: 'object_information.human', value: 'HUMAN' },
  { label: 'object_information.vehicle', value: 'VEHICLE' },
];

export const ACCEPT_IMAGE_ONLY = '.jpg,.jpeg,.png';

export const RELATED_SUGGESTIONS = [
  { label: 'object_information.relatives', value: 1 },
  { label: 'object_information.sameGroup', value: 2 },
  { label: 'object_information.sameAddress', value: 3 },
  { label: 'object_information.owner', value: 4 },
  { label: 'object_information.sameOwner', value: 5 },
];

export const WARNING_CONFIG_LIST = [
  { label: 'identification-event.normal', value: false },
  { label: 'identification-event.warning', value: true },
];

export const WARNING_CONFIG = {
  NORMAL: 'NORMAL',
  WARNING: 'WARNING',
};

export const VIEW_CONSTANT = {
  OBJECT_TRACING: '/trace-management/object-tracing',
};

export const MARKER_TYPE = {
  CAMERA_SELECTED: 'camera-selected',
  CAMERA_DEFAULT: 'camera-default',
  DEFAULT: 'default',
  DO: 'do',
  VANG: 'vang',
  VANG_SELECTED: 'vang-selected',
  CURRENT: 'current',
  TRINH_SAT: 'trinh-sat',
  LOCATION: 'location',
  TIM: 'tim',
  TIM_SELECTED: 'tim-selected',
};

export const MAP_TYPE = {
  COMMON_IO: 1,
  TRAVEL_ROUTE: 2,
  CAMERA_LOCATION: 3,
  HEATMAP: 4,
};
export const NAVIGATION_TYPE = {
  PREV: -1,
  NEXT: 1,
};

export const OBJECT_TYPE_TEXT_KEY = {
  HUMAN: 'HUMAN',
  VEHICLE: 'VEHICLE',
};
export const INTRUSION_TAB = {
  LIST: 'list',
  DASHBOARD: 'dashboard',
};
export const IN_OUT_TAB = {
  LIST: 'list',
  DASHBOARD: 'dashboard',
};

export const OBJECT_TYPE_VALUE = [
  { label: 'object_information.human', value: 1 },
  { label: 'object_information.vehicle', value: 2 },
];

export const TIME_SELECT = [
  { label: 'in_out_event.year', value: 'Y' },
  { label: 'in_out_event.quarter', value: 'Q' },
  { label: 'in_out_event.month', value: 'M' },
  { label: 'in_out_event.week', value: 'W' },
  { label: 'in_out_event.day', value: 'D' },
];

export const STATISTICAL_OBJECT = {
  YEAR: 'Y',
  QUARTER: 'Q',
  MONTH: 'M',
  WEEK: 'W',
  DAY: 'D',
};

export const IN_OUT_SELECT = [
  { label: 'in_out_event.checkin', value: 'ZONE_FLOW_IN' },
  { label: 'in_out_event.checkout', value: 'ZONE_FLOW_OUT' },
];

export const EVENT_STATUS_LIST = [
  { label: 'identification-event.checkin', value: 'ZONE_FLOW_IN' },
  { label: 'identification-event.checkout', value: 'ZONE_FLOW_OUT' },
  { label: 'identification-event.appear', value: 'UNKNOWN' },
  { label: 'identification-event.intrusion', value: 'ZONE_INTRUSION' },
];
export const EVENT_STATUS_VALUE = {
  CHECKIN: 'ZONE_FLOW_IN',
  CHECKOUT: 'ZONE_FLOW_OUT',
  APPEAR: 'UNKNOWN',
  INTRUSION: 'ZONE_INTRUSION',
  ACCESS_CONTROL_IN: 'ZONE_ACCESS_CONTROL_IN',
  ACCESS_CONTROL_OUT: 'ZONE_ACCESS_CONTROL_OUT'
};
export const TYPE_EVENT = {
  IDENTIFICATION: 'identification',
  INOUT: 'in-out',
  INTRUSION: 'intrusion',
  WARNING: 'warning',
};

export const TYPE = {
  UNKNOWN: 'unknown',
};
export const STATUS_CHANNEL = {
  ACTIVE: 1,
  INACTIVE: 0,
};
export const TYPE_MESSAGE = {
  UPDATE_NEW_MESS: 'UpdateNewMessage',
  DELETE_MESS: 'UpdateDeleteMessage',
  READ_MESS: 'UpdateUserReadMessage',
  UPDATE_MODIFY_CONTACT: 'UpdateModifyContact',
  UPDATE_NEW_PHONE_ROOM: 'UpdateNewPhoneRoom',
  UPDATE_DELETE_HISTORY: 'UpdateDeleteHistory',
  UPDATE_LEAVE_ROOM:"UpdateLeaveRoom",
  UPDATE_NEW_USER_JOIN_ROOM:'UpdateNewUserJoinRoom',
  UPDATE_STATUS_ON_CALL:"UpdateChangeStatusOnCall"
};
export const ACTION = {
  VIEW: 0,
  EDIT: 1,
  CREATE: 2,
};
export const PEER_TYPE = {
  CHANNEL: 2,
  USER: 1,
};
export const TAB_COORDINATION = {
  COORDINATION: 'coordinator',
  CONTACT: 'contact',
  CHANNEL: 'channel',
};
export const TAB_WALKIE = {
  WALKIE:'walkie',
  CHAT: 'chat',
  VIEW_MEMBERS: 'member',
  SEND_MESSAGE: 'send',
  CALL:"call"
};

export const OBJECT_GROUP_PERM = {
  CREATE: 'group:create',
  CREATE_CHILD: 'group:create-child',
  ADD_OBJECT: 'group:add-object',
  REMOVE_OBJECT: 'group:remove-object',
  EDIT: 'group:edit',
  DELETE: 'group:delete',
};

export const OBJECT_PERM = {
  EDIT: 'object:edit',
};

export const TRACING_PERM = {
  ADD: 'tracing:add',
  DELETE: 'tracing:delete'
};

export const EVENT_IDENTIFICATION_PERM = {
  DELETE: 'event-identification:delete'
};

export const EVENT_UNVERIFIED_PERM = {
  VIEW: 'event-unverified:view',
  DELETE: 'event-unverified:delete',
  MERGE: 'event-unverified:merge',
  MOVE: 'event-unverified:change-io'
};

export const CHANNEL_PERM = {
  CREATE: 'channel:create',
  EDIT: 'channel:edit',
  DELETE: 'channel:delete',
};

export const LOG_PERM = {
  EXPORT: 'log:export'
}
export const LAYER_MAP = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'

export const commonActionJitsi = {
  video: 'toggleVideo',
  mic: 'toggleAudio',
  close: 'hangup',
  chat: 'toggleChat',
};
export const TYPE_OBJECT_WEAPON = [
  { label: 'weapon_event.gun', value: 'GUN' },
  { label: 'weapon_event.knife', value: 'KNIFE' },
];
