import { computed, defineComponent, ref } from 'vue';
import VueTypes from 'vue-types';
import { forEachNested } from '@/util/common-utils';
import { useI18n } from 'vue-i18n';
import Empty from '@/components/empty/index.vue';

export default defineComponent({
  name: 'Table',
  components: { Empty },
  props: {
    title: VueTypes.string,
    columns: VueTypes.array.def([]),
    clientPagination: VueTypes.bool.def(false),
    showSorterTooltip: VueTypes.bool,
    tableLayout: VueTypes.string,
    sticky: VueTypes.bool,
    showSizeChanger: VueTypes.bool.def(true),
    pagination: VueTypes.bool.def(false),
    pageSizeOptions: VueTypes.array.def([10, 20, 30, 50]),
    paginationSize: VueTypes.string.def('small'),
    pageSize: VueTypes.number.def(10),
    indexColumn: VueTypes.bool.def(false),
    apiFetch: VueTypes.string.required,
    paramFetch: VueTypes.object.def({}),
    header: VueTypes.object,
    keyField: VueTypes.string.def('id'),
    hasCheckbox: VueTypes.bool.def(true),
    checkStrictly: VueTypes.bool.def(true),
    scroll: VueTypes.object.def({ x: 1500 }),
    fetchOnMount: VueTypes.bool.def(true),
    showTotal: VueTypes.bool.def(true),
    dataSource: VueTypes.array.def([]),
    pageNumber: VueTypes.number.def(null),
    hasEmptyText: VueTypes.bool.def(false),
  },
  setup(props, { emit }) {
    const data = [];
    const total = ref(0);
    const page = ref(props.pageNumber ? props.pageNumber : 1);
    const size = ref(props.pageSize);
    const totalPages = computed(() => {
      return total.value / size.value;
    });
    const loading = ref(false);
    const scrollHeight = ref(400);
    const { t } = useI18n();
    const locale = {
      emptyText: <Empty />,
    };
    let tableColumns = computed(() => {
      if (!props.columns.length) return null;
      if (props.indexColumn)
        return [
          {
            title: t('common.stt'),
            width: '110px',
            customRender({ index }) {
              return index + 1 + (page.value - 1) * size.value;
            },
          },
          ...props.columns,
        ];
      return props.columns;
    });

    let selectedRows = ref([]);
    const selectedRowsArray = ref([]);



    const rowSelection = props.hasCheckbox
      ? ref({
          selectedRowKeys: selectedRowsArray,
          checkStrictly: props.checkStrictly,
          onSelect: (record, selected, rows) => {
            selectedRows.value = rows;
            emit('handleSelectCheckbox', rows);
          },
          onSelectAll: (selected, rows) => {
            selectedRows.value = rows;
            emit('handleSelectCheckbox', rows);
          },
          onChange: (selectedRowKeys) => {
            selectedRowsArray.value = selectedRowKeys;
          },
        })
      : null;
      const customRow = (record) => {
        return {
          onClick: () => {
            emit('onRowClicked', record)
          },
        };
      }
    return {
      t,
      locale,
      data,
      total,
      loading,
      tableColumns,
      handleResizeColumn: (col, w) => {
        col.width = w;
      },
      rowSelection,
      selectedRows,
      customRow,
      // pagination
      page,
      size,
      totalPages,
      selectedRowsArray,
      scrollHeight,
    };
  },
  created() {
    if (!this.apiFetch) {
      this.data = this.dataSource;
    } else if (this.fetchOnMount) this.fetchData().then();
  },
  mounted() {
    this.getScrollHeight();
    window.addEventListener('resize', this.getScrollHeight);
  },
  unmounted() {
    window.removeEventListener('resize', this.getScrollHeight);
  },
  methods: {
    getScrollHeight() {
      const table = document.getElementsByClassName('scroll-table-to-top');
      const tableHead = document.getElementsByClassName('ant-table-thead');
      this.scrollHeight = table[0].clientHeight - tableHead[0].clientHeight;
    },
    resetAndFetch() {
      this.page = 1;
      this.size = this.pageSize;
      this.fetchData().then();
    },
    async fetchData(resetPage) {
      try {
        if (resetPage) {
          this.page = 1;
          this.selectedRows = [];
        }
        this.loading = true;
        const response = await this.$callApi(
          this.apiFetch,
          null,
          {
            ...this.paramFetch,
            page: this.page - 1,
            size: this.size,
          },
          this.header
        );
        const data = response.data ? response.data : [];
        if (this.pagination) {
          this.data = this.hasCheckbox
            ? (data.content || []).map((r) => ({ ...r, key: r[this.keyField] }))
            : data.content || [];
          this.total = data.totalElements;
        } else {
          this.data = data;
          if (this.hasCheckbox) {
            this.data.forEach((p) =>
              forEachNested(p, (c) => (c.key = c[this.keyField]))
            );
          }
        }
        this.loading = false;
        this.$forceUpdate();
        const table = document.getElementsByClassName('ant-table-body');
        table[0].scrollTo({ top: 0, behavior: 'smooth' });
      } catch {
        this.data = [];
        this.loading = false;
      } finally {
        this.selectedRowsArray = [];
      }
    },
    onChangePage() {
      this.fetchData(false).then();
      this.selectedRows = [];
      this.$emit('changePage', this.page);
    },
    sizeChange() {
      this.page = 1;
      this.$emit('changePageSize', this.size);
    },
  },
});
