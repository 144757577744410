/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
import moment from 'moment/moment';
import { message, notification } from 'ant-design-vue';
import dayjs from 'dayjs';
import _ from 'lodash';
import { i18n } from '@/main';
import { STATISTICAL_OBJECT } from '@/util/common-constant';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { format } from 'date-fns';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/vi';
import 'dayjs/locale/en';
import 'dayjs/locale/ru'; 

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(updateLocale);

dayjs.updateLocale('vi', {
  weekStart: 1
});
dayjs.updateLocale('en', {
  weekStart: 1
});
dayjs.updateLocale('ru', {
  weekStart: 1
});

const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

const formatDate = (date, format) => {
  if (_.isNil(date)) return '';
  return moment(date).format(format || 'DD/MM/YYYY HH:mm:ss');
};
const formatDateWeapon = (date, formatType) => {
  if (_.isNil(date)) return '';
  return format(Date.parse(date), formatType || 'yyyy/MM/dd HH:mm:ss');
};
const formatAddress = (str) => {
  if (!str) return '';
  const first = str.slice(0, 5);
  const second = str.slice(str.length - 5, str.length);
  return `${first}...${second}`;
};

const checkResponse = (
  response,
  success = () => {},
  fail = () => {
    const { t } = i18n.global;
    notification.error({
      message: t('common.notification'),
      description: response.message || t('common.error'),
      duration: 4,
    });
  }
) => {
  if (response.code === '200') {
    success(response.data);
  } else {
    fail(response.data);
  }
};

export const checkHasPermission = (permissions, value) => {
  return !permissions.includes(value) ? false : true;
};

export const hasPermEdit = (permissions, name) => {
  return !!permissions.includes(`${name}:edit`)
};

const forEachNested = (parent, cb) => {
  if (!cb) return;
  cb(parent);
  if (parent.children && parent.children.length > 0) {
    parent.children.forEach((p) => forEachNested(p, cb));
  }
};

const greaterThanCurrent = (current) =>
  current && current > dayjs().endOf('day');

const lessThanCurrent = (current) =>
  current && current <= dayjs().endOf('day').subtract(1, 'days');

const getText = (value, array) => {
  const s = array.filter((s) => s.value === value);
  if (s && s.length) return s[0].label;
  return '';
};

const censorNumber = (value) => {
  return value.substring(0, 3) + '****' + value.substring(7, value.length - 7);
};

const roundNumber = (number, digit = 2) => {
  if (_.isNil(number)) return null;
  return parseFloat(number.toFixed(digit));
};

const flatten = (array, field) => {
  return array.reduce((newArr, obj) => {
    if (field) {
      newArr.push(obj[field]);
    } else {
      newArr.push(obj);
    }
    if (obj.children) {
      return newArr.concat(flatten(obj.children, field));
    }
    return newArr;
  }, []);
};

const getTextByField = (
  value,
  array,
  fieldSearch = 'value',
  fieldOut = 'label'
) => {
  const s = array.filter((s) => s[fieldSearch] === value);
  if (s && s.length) return s[0][fieldOut];
  return '';
};

export const filterOption = (input, option, filterField = 'label') => {
  return (
    option[filterField]
      .toLowerCase()
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .indexOf(
        input
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      ) >= 0
  );
};
export const trim = (data) => {
  return data ? data.trim().replace(/\s+/g, ' ') : '';
};
export const formatScore = (score) =>
  score !== null
    ? parseFloat(score) === 100
      ? '100'
      : parseFloat(score).toFixed(2)
    : '';

export const formatNumber = (num) => {
  return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '';
};

export const formatNumberReZero = (num) => {
  return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 0;
};

export const setDefaultDateForm = (form, type) => {
  form.dateTo = new Date();
  switch (type) {
    case STATISTICAL_OBJECT.YEAR:
      form.dateFrom = new Date(2023, 0, 1);
      break;
    case STATISTICAL_OBJECT.WEEK:
      form.dateFrom = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );
      break;
    case STATISTICAL_OBJECT.MONTH:
      form.dateFrom = new Date(new Date().getFullYear(), 0);
      break;
    case STATISTICAL_OBJECT.QUARTER:
      form.dateFrom = new Date(new Date().getFullYear(), 0, 1);
      break;
    case STATISTICAL_OBJECT.DAY:
      form.dateFrom = dayjs().subtract(6, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss');
      break;
    default:
      form.dateFrom = dayjs().subtract(6, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss');
  }
};

export const checkNoImage = (image) => {
  return image ? image : require('@/static/img/no-img.png')
}

export const dayBefore = (day) => {
  if (typeof day === 'number') {
    return day * 24 * 60 * 60 * 1000;
  }
  return 0
}
const isThisWeek = (date) =>{
  const startOfWeek = dayjs().startOf('week');
  const endOfWeek = dayjs().endOf('week');
  return date.isSameOrAfter(startOfWeek) && date.isSameOrBefore(endOfWeek);
}
export const convertUnixToDate = (unixTimestamp, locale = 'en') => {
  const date = dayjs.unix(unixTimestamp).locale(locale);
  const now = dayjs().locale(locale);

  if (date.isToday()) {
    const diffInMinutes = now.diff(date, 'minute');
    const diffInHours = now.diff(date, 'hour');

    if (diffInMinutes < 60) {
      return date.fromNow();
    } else if (diffInHours < 24) {
      return date.fromNow();
    }
  } else if (isThisWeek(date)) {
    return date.format('dddd');
  } else {
    return date.format('DD/MM/YYYY');
  }
};

export const copyContent = async (text) => {
  const { t } = i18n.global;
  try {
    await navigator.clipboard.writeText(text);
    message.success(t('common.copy_success'), 1);
  } catch (err) {
    message.error(t('common.error'), 1);
  }

};
export const formatTimeAudio = (second)=> {
  if (second) {
    const minutes = Math.floor(second / 60);
    const remainingSeconds = Math.floor(second % 60);
    return `${minutes < 10 ? '0' : ''}${minutes}:${
      remainingSeconds < 10 ? '0' : ''
    }${remainingSeconds}`;
  }
}
export function useWindowHeight() {
  const windowHeight = ref(window.innerHeight);

  const updateWindowHeight = () => {
    windowHeight.value = window.innerHeight;
  };

  onMounted(() => {
    window.addEventListener('resize', updateWindowHeight);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', updateWindowHeight);
  });

  return { windowHeight };
}

export {
  ellipsis,
  formatDate,
  checkResponse,
  forEachNested,
  greaterThanCurrent,
  lessThanCurrent,
  getText,
  censorNumber,
  roundNumber,
  flatten,
  formatAddress,
  getTextByField,
  formatDateWeapon
};
