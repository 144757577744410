import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { notification } from 'ant-design-vue';
import { checkResponse } from '@/util/common-utils';
import { i18n } from '@/main';

const state = () => ({
  api: ConstantAPI.io_group,
  identEvt: {
    id: '',
    img: '',
    name: '',
    ioId: '',
    type: 0,
    ioGroups: [],
    status: 0,
    time: '',
    area: '',
    characteristic: '',
    bienSo: '',
    classify: '',
    color: '',
    owner: '',
  },
  listSimilar: [],
  listEvent: [],
  latestEvent: {},
  isLastPage: false,
  objectEventMerge: null,
  chartByGender: {},
  chartByCamera: {},
  isLoadingChart: false,
  listObjSearchByImg: [],
  listObjSearchByImgShow: [],
  listAvatarObj: [],
  isShowEmptyText: false,
  loading: false,
});
const actions = {
  async getAll({state}, payload) {
    try {

      const response = await DataService.callApi(ConstantAPI.IDENTIFICATION_EVENT.GET_LIST, null, {...payload, page: payload.page - 1})
      checkResponse(response, () => {
        if(!response.data) {
          state.isLastPage = true;
        } else {
          if(payload.reset) {
            state.isLastPage = false;
            state.listEvent = response.data ? response.data : [];
            if(payload.getLatestEvent) {
              state.latestEvent = response.data[0]
            }
          } else {
            state.listEvent = response.data ? state.listEvent.concat(response.data) : [];
          }
        }
      })
    } catch(e) {
      console.log(e)
    }
  },
  async getDetail({ state, commit }, { id }) {
    const { t } = i18n.global;
    try {
      const response = await DataService.callApi({
        url: ConstantAPI.IDENTIFICATION_EVENT.GET_BY_ID.url + id,
        method: ConstantAPI.IDENTIFICATION_EVENT.GET_BY_ID.method,
      });

      let success = true;
      checkResponse(
        response,
        () => {
          success = true;
          state.identEvt = response.data;
        },
        () => {
          success = false;
          notification.error({
            message: t('common.notification'),
            description: '',
            duration: 4,
          });
        }
      );
      return success;
    } catch (err) {
      console.log(err);
      commit('fetchDataErr', err);
    }
  },
  async getListSimilar({ state }, { id, keyword }) {
    const { t } = i18n.global;
    try {
      const response = await DataService.callApi(
        ConstantAPI.OBJECT_MANAGEMENT.GET_LIST_SIMILAR,
        null,
        {
          ioId: id,
          name: keyword,
        }
      );

      let success = false;
      checkResponse(
        response,
        () => {
          success = true;
          state.listSimilar = response.data;
          if (state.objectEventMerge) {
            state.listSimilar.unshift(state.objectEventMerge);
          }
        },
        () => {
          success = false;
          notification.error({
            message: t('common.notification'),
            description: '',
            duration: 4,
          });
        }
      );
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async getSelectedMergeSimilar({ state }, keyword) {
    const { t } = i18n.global;
    try {
      const response = await DataService.callApi(
        ConstantAPI.OBJECT_MANAGEMENT.GET_BY_CONDITION,
        null,
        {
          name: keyword,
        }
      );

      let success = true;
      checkResponse(
        response,
        () => {
          success = true;
          state.listSimilar.unshift(response.data.content[0]);
          state.objectEventMerge = response.data.content[0];
        },
        () => {
          success = false;
          notification.error({
            message: t('common.notification'),
            description: '',
            duration: 4,
          });
        }
      );
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  setObjectEventMerge({ state }, value) {
    state.objectEventMerge = value;
  },
  // eslint-disable-next-line no-unused-vars
  async delete({ state }, rows) {
    
    const { t } = i18n.global;
    try {
      const response = await DataService.delete(
        ConstantAPI.IDENTIFICATION_EVENT.DELETE.url +
          rows.join(',')
      );
      let success = false;
      checkResponse(
        response,
        () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('identification-event.delete_event_success'),
            duration: 4,
          });
        },
        () => {
          success = false;
          notification.error({
            message: t('common.notification'),
            description: t('identification-event.delete_event_fail'),
            duration: 4,
          });
        }
      );
      return success;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async changeEvent({ state, commit }, params) {
    const { t } = i18n.global;
    try {
      const response = await DataService.callApi(
        ConstantAPI.IDENTIFICATION_EVENT.NAVIGATION,
        null,
        {
          name: params.name,
          type: params.type,
          groupId: params.groupId,
          status: params.status,
          image: params.image,
          cameraId: params.cameraId,
          groupCameraIds: params.groupCameraIds,
          cameraIds: params.cameraIds,
          isWarningConfig: params.isWarningConfig,
          dateFrom: params.dateFrom,
          dateTo: params.dateTo,
          navigationType: params.navigationType,
          currentCreatedAt: params.currentCreatedAt,
          eventId: params.eventId,
          objectId: params.objectId,
        }
      );

      let success = true;
      checkResponse(
        response,
        () => {
          success = true;
          if (response.data) {
            state.identEvt = response.data;
          } else {
            success = false;
          }
        },
        () => {
          success = false;
          notification.error({
            message: t('common.notification'),
            description: '',
            duration: 4,
          });
        }
      );
      return success;
    } catch (err) {
      console.log(err);
      commit('fetchDataErr', err);
    }
  },
  async intrusionNavigation({ state, commit }, params) {
    const { t } = i18n.global;
    try {
      const response = await DataService.callApi(
        ConstantAPI.EVENT.INTRUSION_NAVIGATION,
        null,
        params
      );
      let success = true;
      checkResponse(
        response,
        () => {
          success = true;
          if (response.data) {
            state.identEvt = response.data;
          } else {
            success = false;
          }
        },
        () => {
          success = false;
          notification.error({
            message: t('common.notification'),
            description: '',
            duration: 4,
          });
        }
      );
      return success;
    } catch (err) {
      console.log(err);
      commit('fetchDataErr', err);
    }
  },
  async inOutNavigation({ state, commit }, params) {
    const { t } = i18n.global;
    try {
      const response = await DataService.callApi(
        ConstantAPI.EVENT.IN_OUT_NAVIGATION,
        null,
        params
      );
      let success = true;
      checkResponse(
        response,
        () => {
          success = true;
          if (response.data) {
            state.identEvt = response.data;
          } else {
            success = false;
          }
        },
        () => {
          success = false;
          notification.error({
            message: t('common.notification'),
            description: '',
            duration: 4,
          });
        }
      );
      return success;
    } catch (err) {
      console.log(err);
      commit('fetchDataErr', err);
    }
  },
  resetForm({ state }) {
    state.identEvt = {
      id: '',
      img: '',
      name: '',
      ioId: '',
      type: 0,
      ioGroups: [],
      status: 0,
      time: '',
      area: '',
      characteristic: '',
      bienSo: '',
      classify: '',
      color: '',
      owner: '',
    };
    state.objectEventMerge = null;
    state.listSimilar = [];
  },
  async getDataChart({ state }, params) {
    const { t } = i18n.global;
    try {
      state.isLoadingChart = true;
      const response = await DataService.callApi(
        ConstantAPI.IDENTIFICATION_EVENT.INTRUSION_CHART,
        null,
        {
          ...params,
          cameraIds:
            params && params.cameraIds ? params.cameraIds.join() : null,
        }
      );
      let success = true;
      checkResponse(
        response,
        () => {
          success = true;
          state.isLoadingChart = false;
          state.chartByGender = response.data.chartByGender;
          state.chartByCamera = response.data.chartByCamera;
        },
        () => {
          success = false;
          state.isLoadingChart = false;
          notification.error({
            message: t('common.notification'),
            description: '',
            duration: 4,
          });
        }
      );
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async getObjectByImage({ state, commit }, imgUrl) {
    commit('setLoading', true)
    try {
      if (imgUrl) {
        const res = await DataService.callApi(ConstantAPI.IDENTIFICATION_EVENT.GET_BY_IMAGE,
            null,
            { image: imgUrl}
        );
        checkResponse(
            res,
            () => {
              if (res.data.length) {
                state.listAvatarObj = res.data.map((e, index) => ({
                  ...e,
                  isPick: index === 0
                }))
                state.listObjSearchByImg = state.listAvatarObj[0].ios.map((e) => ({
                  ...e,
                  isPick: false,
                })) || [];
                state.listObjSearchByImgShow = state.listObjSearchByImg;
                state.isShowEmptyText = true
              } else {
                state.listAvatarObj = []
                state.listObjSearchByImg = [];
                state.isShowEmptyText = false
              }
            },
            () => {
            }
        );
      } else {
        state.listObjSearchByImg = [];
        state.isShowEmptyText = false
      }
      commit('setLoading', false)
    } catch (err) {
      console.log(err);
      commit('fetchDataErr', err);
    }
  },
  changeAvaPick({ state }, index) {
    state.listAvatarObj = state.listAvatarObj.map((e, i) => ({
      ...e,
      isPick: index === i
    }))
    state.listObjSearchByImg = state.listAvatarObj[index].ios.map((e) => ({
      ...e,
      isPick: false,
    })) || [];
    state.listObjSearchByImgShow = state.listObjSearchByImg;
  },
  onResetSearchAva({ state }) {
    if (state.listObjSearchByImg.some(e => e.isPick === true)) {
      state.listObjSearchByImg = state.listObjSearchByImg.map((e) => ({
        ...e,
        isPick: false,
      })) || [];
      state.listObjSearchByImgShow = state.listObjSearchByImg;
      return true
    }
    return false
  },
  setDefaultSearchImg({state}) {
    state.listObjSearchByImg = []
    state.listObjSearchByImgShow = []
    state.listAvatarObj = []
    state.isShowEmptyText = false
  },
  filterByPercent({ state }, percentValue) {
    // 1-2-3-4 là value của mảng PERCENT_OPTIONS
    if (!percentValue) {
      state.listObjSearchByImgShow = state.listObjSearchByImg;
    }
    if (percentValue === 1) {
      state.listObjSearchByImgShow = state.listObjSearchByImg.filter(
        (e) => e.confidence >= 20 && e.confidence <= 40
      );
    }
    if (percentValue === 2) {
      state.listObjSearchByImgShow = state.listObjSearchByImg.filter(
        (e) => e.confidence >= 40 && e.confidence <= 60
      );
    }
    if (percentValue === 3) {
      state.listObjSearchByImgShow = state.listObjSearchByImg.filter(
        (e) => e.confidence >= 60 && e.confidence <= 80
      );
    }
    if (percentValue === 4) {
      state.listObjSearchByImgShow = state.listObjSearchByImg.filter(
        (e) => e.confidence >= 80 && e.confidence <= 100
      );
    }
  },
  async getVideoEvent(_, params) {
    try {
      const response = await DataService.callApi(ConstantAPI.IDENTIFICATION_EVENT.VIDEO_EVENT, null, params);
      return response;
    } catch(e) {
      console.log(e)
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
