import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';

const state = () => ({
  listEventWeapon: [],
});

const actions = {
  async getEventWeapon({ state }, { params, reset }) {
    try {
      state.isLoading = true;
      const response = await DataService.callApi(
        ConstantAPI.WEAPON.GET_EVENT,
        null,
        params
      );
      if (reset) {
        state.listEventWeapon = [];
      }
      checkResponse(response, () => {
        if(!response.data) {
          state.isLastPageIntrusion = true
        }
        state.listEventWeapon = response.data
          ? state.listEventWeapon.concat(response.data)
          : state.listEventWeapon;
      });
      state.isLoading = false;
    } catch (err) {
      state.isLoading = false;
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
